export const useDiscount = () => {
  const auth = useAuthStore()
  const user = useUserStore()
  const route = useRoute()

  const storage = useSessionStorage('noPersistentRouteMatchCount', { count: 0 })

  const nonPersistentRoutes = [
    '/*/c/**',
    '/*/checkout**'
  ]

  watch(() => auth.loggedIn, () => {
    if (auth.loggedIn) {
      if (nonPersistentRoutes.some((path) => convertPatternToRegex(path).test(route.path)))
        storage.value.count += 1
    }
    else { storage.value.count = 0 }
  })

  if (auth.loggedIn && user.spendBannerMessage
    && nonPersistentRoutes.some((path) => convertPatternToRegex(path).test(route.path)))
    storage.value.count += 1

  return computed(() => auth.loggedIn && user.spendBannerMessage
    && (storage.value.count <= 1 || !nonPersistentRoutes.some((path) => convertPatternToRegex(path).test(route.path)))
  )
}
